import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"

const About = () => (
  <Layout>
    <SEO/>
    <Introduction/>
  </Layout>
)

export default About