import React from 'react';
import { graphql, StaticQuery } from "gatsby"

import "./introduction.scss"

const Introduction = () => (
  <StaticQuery query={introductionQuery} render={data => {
      return (
        <div className="introWrapper">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div className="intro-row">
              <div className="intro-column-one"><div className="intro" dangerouslySetInnerHTML={{__html: node.html}}/></div>
              <div className="intro-column-two">
                <div className="contact">
                  <h2>Contact</h2>
                  <a href="mailto:vaclavkirschner@gmail.com">vaclavkirschner@gmail.com</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }} 
  />
)

const introductionQuery = graphql`
query introductionQuery {
  allMarkdownRemark( filter: { frontmatter: {title: {eq: "About"}}}) {
    edges {
      node {
        html
        frontmatter {
          title
          date
        }
      }
    }
  }
}
`

export default Introduction